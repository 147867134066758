type KeyMap = { [key: string]: number };

export const standardKeyMap: KeyMap = {
    'z': 48,
    's': 49,
    'x': 50,
    'd': 51,
    'c': 52,
    'v': 53,
    'g': 54,
    'b': 55,
    'h': 56,
    'n': 57,
    'j': 58,
    'm': 59,
    ',': 60,
    'l': 61,
    '.': 62,
    ';': 63,
    '/': 64,
    'q': 60,
    '2': 61,
    'w': 62,
    '3': 63,
    'e': 64,
    'r': 65,
    '5': 66,
    't': 67,
    '6': 68,
    'y': 69,
    '7': 70,
    'u': 71,
    'i': 72,
    '9': 73,
    'o': 74,
    '0': 75,
    'p': 76,
    '[': 77,
    '=': 78,
    ']': 79,
};

//MPP layout
/*
    'z': 45,
    's': 46,
    'x': 47,
    'c': 48,
    'f': 49,
    'v': 50,
    'g': 51,
    'b': 52,
    'n': 53,
    'j': 54,
    'm': 55,
    'k': 56,
    ',': 57,
    'l': 58,
    '.': 59,
    '/': 60,
    '\'': 61,
    'q': 57,
    '2': 58,
    'w': 59,
    'e': 60,
    '4': 61,
    'r': 62,
    '5': 63,
    't': 64,
    'y': 65,
    '7': 66,
    'u': 67,
    '8': 68,
    'i': 69,
    '9': 70,
    'o': 71,
    'p': 72,
    '-': 73,
    '[': 74,
    '=': 75,
    ']': 76,
*/